// ---------------------------------------------------------------
//  layout
// ---------------------------------------------------------------
.l-wrap {
	padding-top: 100px;
	overflow: hidden;
	transition: filter $transition-fast;
}

.l-contents {
}

.l-contentsInner {
}

.l-main {
     float: right;
     width: 100%;
     margin-left: -260px;

	@include max-screen($breakpoint-tablet-min) {
		float: none;
		width: auto;
		margin-left: 0;
		margin-bottom: 40px;
		font-size: 93%;
	}
}

.l-main__inner {
	margin-left: 260px;

	@include max-screen($breakpoint-tablet-min) {
		margin-left: 0;
	}
}