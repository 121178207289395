// ---------------------------------------------------------------
//  newsList
// ---------------------------------------------------------------
.newsList {
    opacity: 0;

    &.is-active {
        opacity: 1;
        transition: opacity $transition-slow;
        transition-delay: 500ms;
    }

    &__inner {

        margin-bottom: 60px;

        @include max-screen(1000px) {
            text-align: center;
            margin-bottom: 100px;
        }

        &__pic {
            overflow: hidden;
            width: 30%;
            display: inline-block;
            margin-right: 40px;
            vertical-align: top;
            max-height: 200px;

            @include max-screen(1000px) {
                display: block;
                margin: auto;
                width: 80%;
                margin-top: 20px;
                text-align: center;
            }

            &__img {
                transform: scale(1.12,1.12);
                transition: transform $transition-slow;
                // min-height: 200px;

                &:hover{
                    transform: none;
                }

            }

        }

        &__box {
            width: 65%;
            display: inline-block;
            margin-top: -4px;

            @include max-screen(1000px) {
                display: block;
                margin: auto;
                width: 80%;
                margin-top: 20px;
            }

            &__tag {
                font-size: 12px;
                margin-bottom: 10px;
            }

            &__ttl {
                font-size: 20px;
                font-weight: bold;
                margin-bottom: 30px;
                cursor: pointer;

                &:hover{
                    opacity: .7;
                }

                a {
                    text-decoration: none;
                }
            }

            &__txt {
                max-height: 100px;
                overflow: hidden;

                @include max-screen($breakpoint-tablet-min) {
                    max-height: 180px;
                }
            }
        }
    }

    // :nth-of-type(1)
    &__inner:nth-of-type(1) {
        margin-bottom: 100px;

        &:nth-of-type(1) .newsList__inner__pic {
            overflow: hidden;
            width: 58%;
            display: inline-block;
            margin-right: 30px;
            vertical-align: top;
            max-height: 400px;

            @include max-screen(1000px) {
                display: block;
                margin: auto;
                width: 90%;
                text-align: center;
            }

        }

        &:nth-of-type(1) .newsList__inner__pic__img {
            // min-height: 400px;
        }

        &:nth-of-type(1) .newsList__inner__box {
            width: 38%;
            display: inline-block;
            margin-top: -4sspx;

            @include max-screen(1000px) {
                display: block;
                margin: auto;
                width: 90%;
                margin-top: 20px;
            }

            &__tag {
                font-size: 14px;
                margin-bottom: 30px;
            }

            &__ttl {
                font-size: 32px;
                line-height: 37px;
                margin-bottom: 20px;

                &:hover{
                    opacity: .7;
                }

                a {
                    text-decoration: none;
                    font-weight: normal;
                }
            }

            &__txt {
                max-height: 200px;
                overflow: hidden;
            }
        }

    }

    &__pagination {
        width: 440px;
        margin: auto;
        margin-top: 100px;

        @include max-screen($breakpoint-tablet-min) {
            width: 310px;
            margin-bottom: 100px;
        }

        &__list {
            display: flex;
            justify-content: space-between;

            &__item {
                width: 70px;

                &__txt {
                    font-size: 14px;

                    a {
                        text-decoration: none;

                        &:hover{
                            opacity: .7;
                        }
                    }

                    svg {
                        vertical-align: sub;
                    }
                }
            }
        }
    }
}

// ---------------------------------------------------------------
//  newsList（変更し時のCSS）
// ---------------------------------------------------------------

.newsListBox {
    .newsDetail__inner {
        margin-bottom: 100px;
    }
}

// ---------------------------------------------------------------
//  title link
// ---------------------------------------------------------------
.newsDetail__inner__box__ttl a{
    text-decoration: none;
}
// ---------------------------------------------------------------
//  aboutMap
// ---------------------------------------------------------------
.archive-title {
	font-size: 23px;
    color:#999;
}
.archive-posts{
    margin-bottom:30px;
}

/* c-pagetitle
--------------------------------------------------------- */
.c-pagetitle {
	margin: 40px 0 40px;
	font-size: 32px;
}