// ---------------------------------------------------------------
//  contactForm
// ---------------------------------------------------------------
.contactForm {
    opacity: 0;

    &.is-active {
        opacity: 1;
        transition: opacity $transition-slow;
        transition-delay: 500ms;
    }

    &__inner {

        width: 800px;
        margin: auto;

        @include max-screen(800px) {
            width: 100%;
        }

        &__txt {
            margin-bottom: 100px;
        }

        &__form {

            &__box {
                margin-bottom: 40px;

                &__left {
                    width: 24%;
                    display: inline-block;

                    @include max-screen($breakpoint-tablet-min) {
                        width: 100%;
                        display: block;
                        margin-bottom: 10px;
                    }

                    span {
                        font-size: 12px;
                        color: #b50b1a;
                    }
                }

                &__right {
                    width: 75%;
                    display: inline-block;
                    vertical-align: middle;

                    @include max-screen($breakpoint-tablet-min) {
                        width: 100%;
                        display: block;
                    }

                    input {
                        background-color: #f4f4f4;
                        border: none;
                        border-radius: 0;
                        height: 40px;
                        width: 100%;
                    }

                    textarea {
                        background-color: #f4f4f4;
                        border: none;
                        border-radius: 0;
                        width: 100%;
                    }

                    &__list {
                        display: flex;
                        justify-content: space-between;
                        flex-wrap: wrap;

                        &__item {
                            width: 32%;
                            background-color: #666;
                            color: #fff;
                            padding: 3px 15px;

                            @include max-screen($breakpoint-tablet-min) {
                                width: 49%;
                                margin-bottom: 5px;
                            }

                            input {
                                width: 10%;
                            }

                            span {
                                vertical-align: 12px;
                            }

                        }

                        .active {
                            background-color: #D51523;
                        }
                    }
                }
            }
        }

        &__agreement {
            margin-top: 100px;
            text-align: center;

            input {
                display: none;
            }

            span {
                font-size: 12px;
                color: #b50b1a;
            }

            label {
                position: relative;
                line-height:2.6;
                padding: 0 20px 0 27px;

                &:before {
                    left: 6px;
                    margin-top: -6px;
                    width: 7px;
                    height: 11px;
                    border-right: 2px solid #0171bd;
                    border-bottom: 2px solid #0171bd;
                    transform: rotate(45deg);
                    opacity: 0;
                    position: absolute;
                    content: "";
                    display: block;
                    top: 50%;
                }

                &:after {
                    left: 0;
                    margin-top: -8px;
                    width: 18px;
                    height: 18px;
                    border: 1px solid #d1d1d1;
                    position: absolute;
                    content: "";
                    display: block;
                    top: 50%;
                }

                a {
                    padding-bottom: 2px;
                    text-decoration: none;
                    border-bottom: 1px solid #202020;
                }
            }

            input[type=checkbox]:checked + label:before {
                opacity: 1;
            }
        }

        &__submit {
            margin-top: 60px;
            text-align: center;

            @include max-screen($breakpoint-tablet-min) {
                margin-bottom: 100px;
            }

            button {
                background-color: #f4f4f4;
                border: none;
                width: 260px;
                text-align: center;
                line-height: 50px;
                font-size: 18px;
            }
        }

    }
}

.contact__inner {
  text-align: center;
  margin-top: 80px;

  @include max-screen($breakpoint-tablet-min) {
      margin-top: 20px;
  }

  iframe {
    width: 640px;
    height: 1920px;

    @include max-screen($breakpoint-tablet-min) {
        width: 100%;
    }
  }

}

.l-footer {
	clear: both;

	@include max-screen($breakpoint-tablet-min) {
		margin-top: 50px;
		margin-bottom: 50px;
    }
    
}