// ---------------------------------------------------------------
//  footer
// ---------------------------------------------------------------
.l-footer {
	clear: both;
	margin-top:50px;
	border-top: 1px solid #eee;
	

	@include max-screen($breakpoint-tablet-min) {
	}
	
	&__inner {
		display: flex;
		justify-content: space-between;
		padding: 30px 0 60px;
		
		@include max-screen($breakpoint-tablet-min) {
			display: block;
			padding: 80px 0 30px;
		}
		
		&__privacy {
			font-size: 14px;
			letter-spacing: .025em;
			
			@include max-screen($breakpoint-tablet-min) {
				margin-bottom: 10px;
				text-align: center;
			}
		
			a {
				padding-bottom: 3px;
				color: $font-color-base;
				text-decoration: none;
				position: relative;
				
				&:before {
					content: "";
					display: block;
					width: 100%;
					height: 1px;
					background-color: $font-color-base;
					position: absolute;
					bottom: 0;
					left: 0;
					opacity: 0;
					transition: opacity $transition-easeOutQuint-slow;
				}
				
				&:hover {
					
					&:before {
						opacity: 1;
					}
				}
			}
		}
		
		&__copy {
			font-size: 14px;
			letter-spacing: .025em;
			
			@include max-screen($breakpoint-tablet-min) {
				text-align: center;
			}
		}
	}
}