// ---------------------------------------------------------------
//  recruitMessage
// ---------------------------------------------------------------
.recruitMessage {

    margin-bottom: 200px;
    opacity: 0;

    &.is-active {
        opacity: 1;
        transition: opacity $transition-slow;
        transition-delay: 500ms;
    }

    &__inner {
        width: 800px;
        margin: auto;

        @include max-screen(900px) {
        	width: 100%;
        }

        &__ttl {
            font-size: 24px;
            margin-left: 120px;
            margin-bottom: 50px;
            margin-top: 15px;

            @include max-screen($breakpoint-mobile-min) {
                margin-left: 0;
                margin-top: 60px;
            }
        }

        &__txt {
            width: 580px;
            margin-left: auto;
            font-size: 16px;
            line-height: 24px;

            @include max-screen($breakpoint-tablet-min) {
                width: 100%;
            }
        }
        
    }
}


// ---------------------------------------------------------------
//  recruitGuide
// ---------------------------------------------------------------
.recruitGuide {
    width: 800px;
    margin: auto;
    margin-bottom: 100px;
    opacity: 0;

    &.is-active {
        opacity: 1;
        transition: opacity $transition-slow;
        transition-delay: 500ms;
    }

    &__list {
        margin-left: 120px;
        margin-top: 15px;

        @include max-screen($breakpoint-tablet-min) {
            margin-left: 95px;
        }

        @include max-screen($breakpoint-mobile-min) {
            margin-left: 0;
            margin-top: 60px;
            font-size: 16px;
        }

        &__left {
            display: inline-block;
            width: 130px;
            vertical-align: top;

            @include max-screen($breakpoint-tablet-min) {
                display: block;
                margin-bottom: 15px;
                font-weight: 600;
            }
        }

        &__right {
            display: inline-block;
            max-width: 540px;

            @include max-screen($breakpoint-tablet-min) {
                display: block;
            }
            p {
                margin-left: 6em;
                padding-left: -6em;
                text-indent: -6em;
            }
        }
    }

    &__list:last-child {
        margin-top: 80px;
    }

    &__btn {
        margin-top: 50px;
        margin-left: 260px;
        font-size: 14px;

        @include max-screen($breakpoint-tablet-min) {
            margin-left: 0;
            text-align: center;
        }
    }
}

.video {
	position: relative;
	height: 0;
	padding: 30px 0 56.25%;
    overflow: hidden;
    margin-bottom:50px;
  }
  .video iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border:none;
  }