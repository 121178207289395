// ---------------------------------------------------------------
//  header
// ---------------------------------------------------------------
.l-header {
	width: 100%;
	height: 100px;
	background-color: rgba(#ffffff,.9);
	position: fixed;
	top: 0;
	left: 0;
	z-index: 50;
	opacity: 0;
	
	@include max-screen($breakpoint-tablet-min) {
		height: 60px;
	}
	
	&.is-active {
		opacity: 1;
		transition: opacity $transition-slow;
	}
	
	&.is-scroll {
		
		.l-header__inner {
			padding-top: 15px;
			padding-bottom: 15px;
			
			@include max-screen($breakpoint-tablet-min) {
				padding-top: 0;
				padding-bottom: 0;
			}
		}
	}
	
	&__inner {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 100%;
		padding-top: 30px;
		transition: padding $transition-easeOutQuint-base;
		
		@include max-screen($breakpoint-tablet-min) {
			padding-top: 0;
		}
		
		&__logo {
			
			img	{
				width: 146px;
				height: 68px;
				padding-bottom: 20px;
				
				@include max-screen($breakpoint-tablet-min) {
					width: 100px;
					height: 47px;
					padding-bottom: 0px;
				}
			}
		}
		
		&__navi {
			
			&__list {
				display: flex;
				
				&__item {
					margin-left: 40px;
					font-size: 14px;
					font-weight: 400;
					letter-spacing: normal;
					
					&:first-child {
						margin-left: 0;
					}
					
					&:last-child {
						padding-left: 40px;
						border-left: 1px solid $font-color-base;
					}
					
					a {
						padding-bottom: 2px;
						text-decoration: none;
					}
				}
			}
		}
		
		&__humberger {
			position: absolute;
			top: 3px;
			right: 0;
			
			.menu-trigger,
			.menu-trigger span {
				display: inline-block;
				transition: all .4s;
				box-sizing: border-box;
			}
			.menu-trigger {
				width: 30px;
				height: 25px;
				position: absolute;
				top: 15px;
				right: 15px;
				z-index: 10;
			}
			.menu-trigger span {
				position: absolute;
				left: 0;
				width: 100%;
				height: 1px;
				background-color: $font-color-base;
				border-radius: 3px;
			}
			.menu-trigger span:nth-of-type(1) {
				top: 1px;
			}
			.menu-trigger span:nth-of-type(2) {
				top: 12px;
			}
			.menu-trigger span:nth-of-type(3) {
				bottom: 1px;
			}
			
			.menu-trigger.is-active span:nth-of-type(1) {
				transform: translateY(11px) rotate(-45deg);
			}
			.menu-trigger.is-active span:nth-of-type(2) {
				opacity: 0;
			}
			.menu-trigger.is-active span:nth-of-type(3) {
				transform: translateY(-11px) rotate(45deg);
			}
		}
	}
}

// ---------------------------------------------------------------
//  l-drawerMenu
// ---------------------------------------------------------------
.l-drawerMenu {
	width: 100%;
	height: 100%;
	padding: 60px 15px 20px;
	background-color: rgba(#fff, .8);
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 20;
	pointer-events: none;
	opacity: 0;
	transition: all $transition-base;
	
	@include min-screen($breakpoint-tablet) {
		display: none !important;
	}
	
	&.is-active {
		pointer-events: auto;
		opacity: 1;
	}
	
	&__list {
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 100%;
		
		&__item {
			margin-bottom: 10%;
			font-size: 18px;
			line-height: 1.5;
			letter-spacing: normal;
			text-align: center;
			
			@include max-screen($breakpoint-legacyMobile-min) {
				margin-bottom: 20px;
				font-size: 16px;
			}
			
			&:last-child {
				margin-bottom: 0;
			}
			
			a {
				color: $font-color-base;
				text-decoration: none;
			}
		}
	}
}