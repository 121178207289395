// ---------------------------------------------------------------
//  aboutVision
// ---------------------------------------------------------------
.aboutVision {

    margin-bottom: 200px;
    opacity: 0;

    &.is-active {
        opacity: 1;
        transition: opacity $transition-slow;
        transition-delay: 500ms;
    }
   
    &__inner {
        width: 800px;
        margin: auto;

        @include max-screen(900px) {
        	width: 100%;
        }

        &__ttl {
            font-size: 24px;
            margin-left: 120px;
            margin-bottom: 30px;
            line-height: 32px;

            @include max-screen($breakpoint-mobile-min) {
                margin-left: 0;
                margin-top: 60px;
            }
        }

        &__txt {
            width: 580px;
            margin-left: auto;
            font-size: 16px;
            line-height: 24px;

            @include max-screen($breakpoint-tablet-min) {
                width: 100%;
            }
        }
        
    }
}

// ---------------------------------------------------------------
//  aboutConcept
// ---------------------------------------------------------------
.aboutConcept {
    width: 800px;
    margin: auto;
    margin-bottom: 200px;
    opacity: 0;

    &.is-active {
        opacity: 1;
        transition: opacity $transition-slow;
        transition-delay: 500ms;
    }
    
    &__list {
        list-style-type: decimal;
        margin-left: 150px;
        font-size: 24px;

        @include max-screen($breakpoint-mobile-min) {
            margin-left: 32px;
            margin-top: 60px;
        }

        &__item {
            margin-bottom: 50px;
            letter-spacing: 2px;
        }
    }
}

// ---------------------------------------------------------------
//  aboutHistory 
// ---------------------------------------------------------------
.aboutHistory  {
    width: 800px;
    margin: auto;
    margin-bottom: 200px;
    opacity: 0;

    &.is-active {
        opacity: 1;
        transition: opacity $transition-slow;
        transition-delay: 500ms;
    }

    &__list {
        margin-left: 120px;
        margin-bottom: 20px;

        @include max-screen($breakpoint-mobile-min) {
            margin-left: 0;
            margin-top: 60px;
            font-size: 16px;
        }

        &__left {
            display: inline-block;
            width: 100px;

            @include max-screen($breakpoint-mobile-min) {
                margin-bottom: 8px;
                font-weight: 600;
                display: block;
            }
        }

        &__right {
            display: inline-block;
        }
    }
}

// ---------------------------------------------------------------
//  aboutOutline
// ---------------------------------------------------------------
.aboutOutline {
    width: 800px;
    margin: auto;
    margin-bottom: 100px;
    opacity: 0;

    &.is-active {
        opacity: 1;
        transition: opacity $transition-slow;
        transition-delay: 500ms;
    }

    &__list {
        margin-left: 120px;
        margin-bottom: 20px;

        @include max-screen($breakpoint-mobile-min) {
            margin-left: 0;
            margin-top: 60px;
            font-size: 16px;
        }

        &__left {
            display: inline-block;
            width: 130px;
            vertical-align: top;

            @include max-screen($breakpoint-mobile-min) {
                margin-bottom: 8px;
                font-weight: 600;
                display: block;
            }
        }

        &__right {
            display: inline-block;

            br {
                display: none;
            }

            @include max-screen($breakpoint-mobile-min) {
                br {
                    display: block;
                }
            }
        }
    }
}

// ---------------------------------------------------------------
//  aboutMap
// ---------------------------------------------------------------
.aboutMap {
    // width: 800px;
    width: 100%;
    margin: auto;
    opacity: 0;

    &.is-active {
        opacity: 1;
        transition: opacity $transition-slow;
        transition-delay: 500ms;
    }

    iframe {
        // -webkit-filter: grayscale(100%);
        // -moz-filter: grayscale(100%);
        // -ms-filter: grayscale(100%);
        // -o-filter: grayscale(100%);
        // filter: grayscale(100%);
    }

    @include max-screen(800px) {
        iframe {
            width: 100%;
            margin-bottom: 100px;
        }
    }
}
