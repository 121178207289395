/* reset
--------------------------------------------------------- */
body {
	text-align: center;
	font-size: $font-size-base;
	font-weight: 500;
	margin: 0px;
	padding: 0px;
	background-color: #fff;
	font-family: $font-family-base;
	-webkit-text-size-adjust: none;
	
	@include max-screen($breakpoint-tablet-min) {
		font-size: $font-size-small-base;
	}
	
	@media all and (-ms-high-contrast:none){
		font-family: $font-family-ie;
		font-weight: 400;
	}
}
div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, form, fieldset, input, button, textarea, select, p, blockquote, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary {
	font-size: 1em;
	line-height: $line-height-base;
	letter-spacing: $letter-spacing-base;
	margin: 0px;
	padding: 0px;
	color: $font-color-base;
	font-family: $font-family-base;
	text-align: left;
	box-sizing: border-box;
	
	@media all and (-ms-high-contrast:none){
		font-family: $font-family-ie;
	}
}
table {
	font-size: inherit; /* モダンブラウザ向け */
	font: 100%; /* Win IE 5-5.5､6(後方互換モード)向け */
}
fieldset, img {
	border:0;
}
address, caption, cite, code, dfn, th, var {
	font-style: normal;
	font-weight: normal;
}
ol, ul {
	list-style: none;
}
caption, th {
	text-align: left;
}
h1, h2, h3, h4, h5, h6 {
	font-size: 100%;
	font-weight: normal;
}
q:before, q:after {
	content: '';
}
a {
	color: $font-color-link;
	text-decoration: none;

	&:visited {
		color: $font-color-link;
	}
	&:hover {
		text-decoration: none;
	}
}
a {
    overflow: hidden;
    outline: none;
}
img {
	max-width: 100%;
	width: auto;
	height: auto;
	vertical-align: middle;
}
abbr, acronym {
	border:0;
}
input[type="radio"],input[type="checkbox"],label,button,input[type="submit"] {
	cursor: pointer;
}
input[type="submit"] {
	cursor: pointer;
}
input[type="text"], input[type="search"], input[type="tel"], input[type="url"], input[type="email"], input[type="password"], input[type="date"], input[type="number"] {
	padding: 3px 5px;
	border: 1px solid $gray-lighter;
	box-sizing: border-box;
	border-radius: 3px;
}
select {
	padding: 5px 5px;
	border: 1px solid $gray-lighter;
	box-sizing: border-box;
	-moz-border-radius: 3px;
	-webkit-border-radius: 3px;
	border-radius: 3px;
}
textarea {
	padding: 4px 5px;
	border: 1px solid $gray-lighter;
	box-sizing: border-box;
	-moz-border-radius: 3px;
	-webkit-border-radius: 3px;
	border-radius: 3px;
	vertical-align: top;
}
/* Webkit */
::-webkit-input-placeholder {
	color: $font-color-placeholder;
	opacity: 1;
}
/* Firefox 18 以前 */
:-moz-placeholder {
	color: $font-color-placeholder;
}
/* Firefox 19 以降 */
::-moz-placeholder {
	color: $font-color-placeholder;

	/* Firefox 19 以降のデフォルトでは */
	/* color ではなく opacity で色合いを調整しているため */
	/* 文字色を指定する場合、opacity を 1 にする必要がある */
	opacity: 1;
}
/* IE 10 以降 */
:-ms-input-placeholder {
	color: $font-color-placeholder !important;
}
/* CSS4では以下のような名前の擬似クラスになるらしい */
/* おそらく今のところ対応ブラウザはない */
:placeholder-shown {
	color: $font-color-placeholder;
}
