// ---------------------------------------------------------------
//  side
// ---------------------------------------------------------------
.l-side {
	width: 230px;
	float: left;

	@include max-screen($breakpoint-tablet-min) {
		float: none;
		width: auto;
		font-size: 93%;
	}
}