@charset "utf-8";
/******************************************************************************

フォントサイズ デフォルト12pxの場合

10px/84%  11px/92%  12px/100%  13px/109% 14px/117% 15px/125% 16px/134%
17px/142% 18px/150% 19px/159% 20px/167% 21px/175% 22px/184% 23px/192%
24px/200% 25px/209% 26px/217%

******************************************************************************/

/******************************************************************************

フォントサイズ デフォルト13pxの場合

10px/77%  11px/85%  12px/93%  13px/100% 14px/108% 15px/116% 16px/124%
17px/131% 18px/139% 19px/147% 20px/154% 21px/162% 22px/170% 23px/177%
24px/185% 25px/193% 26px/200%

******************************************************************************/

/******************************************************************************

フォントサイズ デフォルト14pxの場合

10px/72%  11px/79%  12px/86%  13px/93% 14px/100% 15px/108% 16px/115%
17px/122% 18px/129% 19px/136% 20px/143% 21px/150% 22px/158% 23px/165%
24px/172% 25px/179% 26px/186%

******************************************************************************/

/******************************************************************************

フォントサイズ デフォルト16pxの場合

10px/63%  11px/69%  12px/75%  13px/82% 14px/88% 15px/94% 16px/100%
17px/107% 18px/113% 19px/119% 20px/125% 21px/132% 22px/138% 23px/144%
24px/150% 25px/157% 26px/163% 27px/169% 28px/175% 29px/181% 30px/188% 35px/219%
36px/225% 40px/250% 45px/281%

******************************************************************************/

/* =========================================================
 Foundation
========================================================= */

@import "common/_variables";
@import "common/_mixin";
@import "common/_sanitize";

/* =========================================================
 Layout
========================================================= */

@import "layout/_header";
@import "layout/_layout";
@import "layout/_side";
@import "layout/_footer";

/* =========================================================
 Common
========================================================= */

@import "common/_common";

/* =========================================================
 Library
========================================================= */

@import "lib/_animate.min";

/* =========================================================
 Page
========================================================= */

@import "page/_top";
@import "page/_works";
@import "page/_about";
@import "page/_recruit";
@import "page/_news";
@import "page/_newsdetail";
@import "page/_contact";
@import "page/_privacy";

/* =========================================================
 Utility
========================================================= */

@import "common/_utility";

/* =========================================================
 Print
========================================================= */
@import "common/_print";
