//メディアクエリの設定
// 使用例
// .hoge {
//  font-size: 20px;
//  @include max-screen($breakpoint-tablet) {
//    font-size: 18px;
//  }
//  @include max-screen($breakpoint-mobile) {
//    font-size: 16px;
//  }
//}
$breakpoint-pc-over: 1025px;
$breakpoint-pc: 1024px;
$breakpoint-pc-min: 1023px;
$breakpoint-tablet-over: 769px;
$breakpoint-tablet: 768px;
$breakpoint-tablet-min: 767px;
$breakpoint-mobile-over: 426px;
$breakpoint-mobile: 425px;
$breakpoint-mobile-min: 424px;
$breakpoint-legacyMobile-over: 376px;
$breakpoint-legacyMobile: 375px;
$breakpoint-legacyMobile-min: 374px;

@mixin max-screen($break-point) {
	@media screen and (max-width: $break-point) {
		@content;
	}
}

@mixin min-screen($break-point) {
	@media screen and (min-width: $break-point) {
		@content;
	}
}

@mixin screen($break-point-min, $break-point-max) {
	@media screen and (min-width: $break-point-min) and (max-width: $break-point-max) {
		@content;
	}
}

/* リンクカラー
--------------------------------------------------------- */
@mixin link-color($color) {
	color: $color;

	&:hover, &:active, &:focus {
		color: $color;
	}
}

/* 絶対位置の指定
--------------------------------------------------------- */
@mixin absolute-top-left($top: 0, $left: 0) {
	position: absolute;
	top: $top;
	left: $left;
}

@mixin absolute-top-right($top: 0, $right: 0) {
	position: absolute;
	top: $top;
	right: $right;
}

@mixin absolute-bottom-left($bottom: 0, $left: 0) {
	position: absolute;
	bottom: $bottom;
	left: $left;
}

@mixin absolute-bottom-right($bottom: 0, $right: 0) {
	position: absolute;
	bottom: $bottom;
	right: $right;
}

/* object-fit
--------------------------------------------------------- */
@mixin img-fit($position: center) {
	width: 100%;
	height: 100%;
	object-position: $position;
	object-fit: cover;
	font-family: 'object-fit: cover; object-position: center;'
}

/* Image Resizing Algorithm
--------------------------------------------------------- */
@mixin imageRendering() {
	-ms-interpolation-mode: nearest-neighbor;   /* IE8+ */
	image-rendering: -webkit-optimize-contrast; /* Safari (WebKit) */
	image-rendering: -moz-crisp-edges;          /* Firefox (Gecko) */
	image-rendering: -o-crisp-edges;            /* Opera 12.x */
	image-rendering: pixelated;                 /* Chrome 41+, Opera 29+ (CSS4) */
}

/* Font awesome
--------------------------------------------------------- */
@mixin awesome($size: 12px) {
	font-size: $size;
	font-style: normal;
	font-weight: normal;
	font-family: $font-family-awesome;
	line-height: 1;
}