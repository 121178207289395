/* vertical-align - utility
--------------------------------------------------------- */
.u-va-t{
	vertical-align: top !important;
}

.u-va-m{
	vertical-align: middle !important;
}

.u-va-b{
	vertical-align: bottom !important;
}

/* border - utility
--------------------------------------------------------- */
/* border-radius */
.u-br-3{
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	-ms-border-radius: 3px;
}

.u-br-4{
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;

	-ms-border-radius: 4px;
}

.u-br-8{
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;

	-ms-border-radius: 8px;
}

.u-br-12{
	-webkit-border-radius: 12px;
	-moz-border-radius: 12px;
	border-radius: 12px;

	-ms-border-radius: 12px;
}

.u-br-16{
	-webkit-border-radius: 16px;
	-moz-border-radius: 16px;
	border-radius: 16px;

	-ms-border-radius: 16px;
}

/* border-none */
.u-br-0{
	border: none;
}

.u-br-t-0{
	border-top: none;
}

.u-br-r-0{
	border-right: none;
}

.u-br-b-0{
	border-bottom: none;
}

.u-br-l-0{
	border-left: none;
}

/* clearfix - utility
--------------------------------------------------------- */
.u-clear {
	clear: both;
}
.u-clearfix {
	zoom: 100%;
}
.u-clearfix:after {
	content: "";
	clear: both;
	height: 0;
	display: block;
	visibility: hidden;
}

/* display - utility
--------------------------------------------------------- */
.u-d-tb{
	display: table !important;
}

.u-d-tbc{
	display: table-cell !important;
}

.u-d-b{
	display: block !important;
}

.u-d-ib{
	display: inline-block !important;
}

.u-d-n{
	display: none !important;
}

/* float - utility
--------------------------------------------------------- */
.u-fl-l{
	float: left !important;
}

.u-fl-r{
	float: right !important;
}

/* margin - utility
--------------------------------------------------------- */
/* auto margin */
.u-m-c{
	margin-right: auto !important;
	margin-left: auto !important;
}

/* Top margin */
.u-mt-0{
	margin-top: 0 !important;
}

.u-mt-10{
	margin-top: 10px !important;
}

.u-mt-15{
	margin-top: 15px !important;
}

.u-mt-20{
	margin-top: 20px !important;
}

.u-mt-25{
	margin-top: 25px !important;
}

.u-mt-35{
	margin-top: 35px !important;
}

.u-mt-40{
	margin-top: 40px !important;
}

.u-mt-45{
	margin-top: 45px !important;
}

.u-mt-50{
	margin-top: 50px !important;
}

.u-mt-55{
	margin-top: 55px !important;
}

.u-mt-60{
	margin-top: 60px !important;
}

/* Right margin */
.u-mr-0{
	margin-right: 0 !important;
}

.u-mr-10{
	margin-right: 10px !important;
}

.u-mr-15{
	margin-right: 15px !important;
}

.u-mr-20{
	margin-right: 20px !important;
}

.u-mr-25{
	margin-right: 25px !important;
}

.u-mr-30{
	margin-right: 30px !important;
}

.u-mr-35{
	margin-right: 35px !important;
}

.u-mr-40{
	margin-right: 40px !important;
}

.u-mr-45{
	margin-right: 45px !important;
}

.u-mr-50{
	margin-right: 50px !important;
}

/* Bottom margin */
.u-mb-0{
	margin-bottom: 0 !important;
}

.u-mb-10{
	margin-bottom: 10px !important;
}

.u-mb-15{
	margin-bottom: 15px !important;
}

.u-mb-20{
	margin-bottom: 20px !important;
}

.u-mb-25{
	margin-bottom: 25px !important;
}

.u-mb-30{
	margin-bottom: 30px !important;
}

.u-mb-35{
	margin-bottom: 35px !important;
}

.u-mb-40{
	margin-bottom: 40px !important;
}

.u-mb-45{
	margin-bottom: 45px !important;
}

.u-mb-50{
	margin-bottom: 50px !important;
}

/* Left margin */
.u-ml-0{
	margin-left: 0 !important;
}

.u-ml-5{
	margin-left: 5px !important;
}

.u-ml-10{
	margin-left: 10px !important;
}

.u-ml-15{
	margin-left: 15px !important;
}

.u-ml-20{
	margin-left: 20px !important;
}

.u-ml-25{
	margin-left: 25px !important;
}

.u-ml-30{
	margin-left: 30px !important;
}

.u-ml-35{
	margin-left: 35px !important;
}

.u-ml-40{
	margin-left: 40px !important;
}

.u-ml-45{
	margin-left: 45px !important;
}

.u-ml-50{
	margin-left: 50px !important;
}

/* padding - utility
--------------------------------------------------------- */
/* Top padding */
.u-pt-0{
	margin-top: 0 !important;
}

.u-pt-10{
	margin-top: 10px !important;
}

.u-pt-15{
	margin-top: 15px !important;
}

.u-pt-20{
	margin-top: 20px !important;
}

.u-pt-25{
	margin-top: 25px !important;
}

.u-pt-30{
	margin-top: 30px !important;
}

.u-pt-35{
	margin-top: 35px !important;
}

.u-pt-40{
	margin-top: 40px !important;
}

.u-pt-45{
	margin-top: 45px !important;
}

/* Right padding */
.u-pr-0{
	padding-right: 0 !important;
}

.u-pr-5{
	padding-right: 5px !important;
}

.u-pr-10{
	padding-right: 10px !important;
}

.u-pr-15{
	padding-right: 15px !important;
}

.u-pr-20{
	padding-right: 20px !important;
}

.u-pr-25{
	padding-right: 25px !important;
}

.u-pr-30{
	padding-right: 30px !important;
}

.u-pr-35{
	padding-right: 35px !important;
}

.u-pr-40{
	padding-right: 40px !important;
}

.u-pr-45{
	padding-right: 45px !important;
}

.u-pr-50{
	padding-right: 50px !important;
}

/* Bottom padding */
.u-pb-0{
	padding-bottom: 0 !important;
}

.u-pb-5{
	padding-bottom: 5px !important;
}

.u-pb-10{
	padding-bottom: 10px !important;
}

.u-pb-15{
	padding-bottom: 15px !important;
}

.u-pb-20{
	padding-bottom: 20px !important;
}

.u-pb-25{
	padding-bottom: 25px !important;
}

.u-pb-30{
	padding-bottom: 30px !important;
}

.u-pb-35{
	padding-bottom: 35px !important;
}

.u-pb-40{
	padding-bottom: 40px !important;
}

.u-pb-45{
	padding-bottom: 45px !important;
}

.u-pb-50{
	padding-bottom: 50px !important;
}

/* Left padding */
.u-pl-0{
	padding-left: 0 !important;
}

.u-pl-5{
	padding-left: 5px !important;
}

.u-pl-10{
	padding-left: 10px !important;
}

.u-pl-15{
	padding-left: 15px !important;
}

.u-pl-20{
	padding-left: 20px !important;
}

.u-pl-25{
	padding-left: 25px !important;
}

.u-pl-30{
	padding-left: 30px !important;
}

.u-pl-35{
	padding-left: 35px !important;
}

.u-pl-40{
	padding-left: 40px !important;
}

.u-pl-45{
	padding-left: 45px !important;
}

.u-pl-50{
	padding-left: 50px !important;
}

/* position - utility
--------------------------------------------------------- */
.u-pos-a{
	position: absolute !important;
}

.u-pos-r{
	position: relative !important;
}

/* text-align - utility
--------------------------------------------------------- */
.u-ta-l{
	text-align: left !important;
}

.u-ta-c{
	text-align: center !important;
}

.u-ta-r{
	text-align: right !important;
}

.u-ta-j{
	text-align: justify !important;
}

/* text-decoration - utility
--------------------------------------------------------- */
.u-fw-n{
	font-weight: normal !important;
}

.u-fw-b{
	font-weight: bold !important;
}

.u-td-u{
	text-decoration: underline !important;
}

.u-td-nu{
	text-decoration: none !important;
}

/* text-truncate - utility
--------------------------------------------------------- */
.u-tt{
	display: block;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	word-wrap: normal;
	/* for IE 8/9 */
}

/* font-feature-settings - utility
--------------------------------------------------------- */
.u-ffs-p {
	font-feature-settings : "palt";
	letter-spacing: 0.1em;
}


/* font-size - utility
--------------------------------------------------------- */
.u-fs-n {
	font-size: $font-size-base;
}
.u-fs-l {
	font-size: $font-size-large-base;
}
.u-fs-ll {
	font-size: $font-size-large-x-base;
}
.u-fs-lll {
	font-size: $font-size-large-xx-base;
}
.u-fs-s {
	font-size: $font-size-small-base;
}
.u-fs-ss {
	font-size: $font-size-small-x-base;
}

/* color - utility
--------------------------------------------------------- */
.u-c-red {
	color: $font-color-red;
}

/* indent - utility
--------------------------------------------------------- */
.u-indent {
	padding-left: 1em;
	text-indent: -1em;
}

/* width - utility
--------------------------------------------------------- */
.u-w-auto{
	width: auto !important;
}

.u-w-10{
	width: 10px !important;
}

.u-w-20{
	width: 20px !important;
}

.u-w-30{
	width: 30px !important;
}

.u-w-40{
	width: 40px !important;
}

.u-w-50{
	width: 50px !important;
}

.u-w-60{
	width: 60px !important;
}

.u-w-70{
	width: 70px !important;
}

.u-w-80{
	width: 80px !important;
}

.u-w-90{
	width: 90px !important;
}

.u-w-100{
	width: 100px !important;
}

.u-w-110{
	width: 110px !important;
}

.u-w-120{
	width: 120px !important;
}

.u-w-130{
	width: 130px !important;
}

.u-w-140{
	width: 140px !important;
}

.u-w-150{
	width: 150px !important;
}

.u-w-200{
	width: 200px !important;
}

.u-w-250{
	width: 250px !important;
}

.u-w-300{
	width: 300px !important;
}

.u-w-350{
	width: 350px !important;
}

.u-w-400{
	width: 400px !important;
}

.u-w-450{
	width: 450px !important;
}

.u-w-500{
	width: 500px !important;
}

.u-w-550{
	width: 550px !important;
}

.u-maw-full{
	max-width: 100% !important;
}

.u-maw-half{
	max-width: 50% !important;
}

.u-maw-10{
	max-width: 10px !important;
}

.u-maw-20{
	max-width: 20px !important;
}

.u-maw-30{
	max-width: 30px !important;
}

.u-maw-40{
	max-width: 40px !important;
}

.u-maw-50{
	max-width: 50px !important;
}

.u-maw-60{
	max-width: 60px !important;
}

.u-maw-70{
	max-width: 70px !important;
}

.u-maw-80{
	max-width: 80px !important;
}

.u-maw-90{
	max-width: 90px !important;
}

.u-maw-100{
	max-width: 100px !important;
}

.u-maw-110{
	max-width: 110px !important;
}

.u-maw-120{
	max-width: 120px !important;
}

.u-maw-130{
	max-width: 130px !important;
}

.u-maw-140{
	max-width: 140px !important;
}

.u-maw-150{
	max-width: 150px !important;
}

.u-maw-200{
	max-width: 200px !important;
}

.u-maw-250{
	max-width: 250px !important;
}

.u-maw-300{
	max-width: 300px !important;

	@include max-screen($breakpoint-tablet-min) {
		max-width: 100% !important;
	}
}

.u-maw-350{
	max-width: 350px !important;

	@include max-screen($breakpoint-tablet-min) {
		max-width: 100% !important;
	}
}

.u-maw-400{
	max-width: 400px !important;

	@include max-screen($breakpoint-tablet-min) {
		max-width: 100% !important;
	}
}

.u-maw-450{
	max-width: 450px !important;

	@include max-screen($breakpoint-tablet-min) {
		max-width: 100% !important;
	}
}

.u-maw-500{
	max-width: 500px !important;

	@include max-screen($breakpoint-tablet-min) {
		max-width: 100% !important;
	}
}

.u-maw-550{
	max-width: 550px !important;

	@include max-screen($breakpoint-tablet-min) {
		max-width: 100% !important;
	}
}


/* misc - utility
--------------------------------------------------------- */
.u-ws-nowrap{
	white-space: nowrap;
}

.u-mx-img{
	max-width: 100%;
}

.u-tx-inside{
	margin-left: 1em;
	text-indent: -1em;
}

.u-cur-pointer {
	cursor: pointer;
}

.u-op-hover {
	transition: opacity $transition-base;

	&:hover {
		opacity:0.7;
	}

	&__article:hover {
		opacity:0.3;
	}
}

.fancybox-lock .u-op-hover {
	transition: all 0s;
}

.ui-datepicker-trigger {
	cursor: pointer;
}

.ui-datepicker {
	z-index: 1000 !important;
}

.ui-datepicker-year {
	height: 30px;
}

.ui-tl-f {
	table-layout: fixed;
}

.u-lt-n {
	color: $font-color-base;
	text-decoration: none;
}

.u-require {
	color: #f22;
	font-size: 12px;
	font-weight: normal;
}

.js-fadeBlockWrap {
	overflow: hidden;
}

.js-fadeInUpBlock {
	opacity: 0;
	transform: translate(0,60px);
}

.is-fadeInUpBlock__active {
	opacity: 1.0;
	transform: none;
	transition: opacity $transition-slow, transform $transition-slow;
}

.js-fadeIn3dBlock {
	opacity: 0;
	transform: scale(1.12,1.12);
	filter: blur(5px);
}

.is-fadeIn3dBlock__active {
	opacity: 1.0;
	transform: none;
	filter: none;
	transition: opacity $transition-slow, transform $transition-slow, filter $transition-slow;
}


@include min-screen($breakpoint-tablet) {
	.u-sp-only {
		display: none;
	}
}

@include max-screen($breakpoint-tablet-min) {
	.u-pc-only {
		display: none;
	}
}