// ---------------------------------------------------------------
//  recruitMessage
// ---------------------------------------------------------------
.privacypolicy {

    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 200px;
    opacity: 0;

    &.is-active {
        opacity: 1;
        transition: opacity $transition-slow;
        transition-delay: 500ms;
    }

    &__inner {
        max-width: 1000px;
        margin: auto;

        @include max-screen(900px) {
        	width: 100%;
        }

        &__ttl {
            font-size: 20px;
            margin: 50px 0 20px;
            text-decoration: underline;

            @include max-screen($breakpoint-mobile-min) {
                margin-left: 0;
                margin: 60px 0 30px;
            }
        }

        &__txt {
            // width: 1000px;
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 30px;
            padding-left: 16px;

            @include max-screen($breakpoint-tablet-min) {
                width: 100%;
            }
        }

        &__list {
            list-style: circle;
            font-size: 16px;
            margin-bottom: 30px;
            padding-left: 50px;

            li {
                line-height: 1.1;
                margin-top: 0px;
                margin-bottom: 10px;
            }
            @include max-screen($breakpoint-tablet-min) {
                width: 100%;
            }
        }
    }
}
