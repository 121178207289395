/* コンテナ
--------------------------------------------------------- */
.c-container-fluid {
	padding-left: 24px;
	padding-right: 24px;
	box-sizing: border-box;

	@include max-screen($breakpoint-tablet-min) {
		padding-left: 15px;
		padding-right: 15px;
	}
}

.c-container {
	max-width: 100%;
	width: $width-base;
	margin: 0 auto;

	@include max-screen($breakpoint-tablet-min) {
		padding: 20px 3% 10px 3%;
	}
}


/* パンくず
--------------------------------------------------------- */
.c-breadcrumb {
}


/* c-swipeBtn
--------------------------------------------------------- */
a.c-swipeBtn {
	display: block;
	color: $brand-color;
	text-decoration: none;
	border: 1px solid $brand-color;
	position: relative;
	transition: $transition-fast;
	
	&:before {
		content: '';
		display: block;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		background-color: $brand-color;
		transform: translateX(-101%);
		transition: $transition-fast;
	}
	
	&:hover {
		color: #fff;
		
		&:before {
			transform: translateX(0);
		}
	}
}

/* c-hoverLine
--------------------------------------------------------- */
.c-hoverLine {
	display: inline-block;
	padding-bottom: 4px;
	position: relative;
	overflow: hidden;
	text-decoration: none;
	vertical-align: middle;
	
	&:before {
		content: "";
		display: block;
		width: 100%;
		height: 1px;
		background-color: $font-color-base;
		position: absolute;
		bottom: 0;
		left: 0;
		transform: translateX(-105%);
		transition: transform $transition-easeOutQuint-slow;
	}
	
	&:hover {
		
		&:before {
			transform: none;
		}
	}
}

/* c-hoverLineReverse
--------------------------------------------------------- */
.c-hoverLineReverse {
	display: inline-block;
	padding-bottom: 4px;
	position: relative;
	overflow: hidden;
	text-decoration: none;
	vertical-align: middle;
	
	&:before {
		content: "";
		display: block;
		width: 100%;
		height: 1px;
		background-color: $font-color-base;
		position: absolute;
		bottom: 0;
		left: 0;
		transform: none;
		transition: transform $transition-easeOutQuint-slow;
	}
	
	&:hover {
		
		&:before {
			transform: translateX(105%);
		}
	}
}

/* ボタン
--------------------------------------------------------- */
.c-btn{
	display: inline-block;
	max-width: 100%;
	padding: 10px 12px;
	background: #000;
	text-align: center;
	color: #fff;
	font-weight: bold;
	border: none;
	border-radius: 0;
	-moz-border-radius: 0;
	-webkit-border-radius: 0;
	-webkit-appearance: none;
	box-sizing: border-box;
}

/* c-simpleTitle
--------------------------------------------------------- */
.c-simpleTitle {
	margin-bottom: 80px;
	font-size: 44px;
	font-weight: 600;
	text-align: center;
	line-height: 1.2;
	letter-spacing: normal;
	
	@include max-screen($breakpoint-tablet-min) {
		font-size: 32px;
		margin-bottom: 40px;
	}
}

/* c-pagetitle
--------------------------------------------------------- */
.c-pagetitle {
	margin: 120px 0 90px;
	font-size: 62px;
}

/* c-title
--------------------------------------------------------- */
.c-title {
	font-size: 14px;
	display: inline;
	position: relative;

	&:after {
		content: "";
		background: url(../images/common/slsh.png)no-repeat;
		background-size: 100%;
		position: absolute;
		height: 60px;
		width: 70px;
		right: -45px;
		top: -10px;
	}
}

/* list
--------------------------------------------------------- */
.c-list {

}

/* WordPress - Navi
--------------------------------------------------------- */
.nav-links .nav-previous {
	float: left;
}
.nav-links .nav-next {
	float: right;
}
.wp-pagenavi {
	padding: 20px 0 0 0;
	text-align: center;
}
.wp-pagenavi a {
	width: 34px;
	height: 34px;
	line-height: 34px;
	margin: 0 4px 8px;
	padding: 0;
	display: inline-block;
	border: 1px solid #a5a5a5;
	text-decoration: none;
	vertical-align: top;
	text-align: center;
	border-radius: 5px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
}
.wp-pagenavi span {
	width: 34px;
	height: 34px;
	line-height: 34px;
	margin: 0 4px 8px;
	padding: 0;
	display: inline-block;
	border: 1px solid #a5a5a5;
	vertical-align: top;
	text-align: center;
	border-radius: 5px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
}
.wp-pagenavi a:hover,
.wp-pagenavi .current {
	color: #fff;
	background: #aa8a66;
}
.page-numbers__wrap {

	&--line {
		margin-top: 30px;
		border-top: 3px dashed $brand-color;
	}

	ul.page-numbers {
		padding-top: 30px;
		text-align: center;

		@include max-screen($breakpoint-tablet-min) {
			text-align: left;
		}

		li {
			display: inline-block;
			vertical-align: top;
			margin-bottom: 10px;

			.page-numbers {
				display: block;
				width: 30px;
				height: 30px;
				margin: 0 3px;
				line-height: 30px;
				border-radius: 50%;
				color: $font-color-base;
				background-color: #f4f3f3;
				text-decoration: none;
				transition: all .3s;
				text-align: center;
			}

			a:hover,
			.current {
				background-color: #e2edd4;
			}

			.prev,
			&:first-child a {
				width: auto;
				padding: 0 10px 0 26px;
				border-radius: 15px;
				position: relative;
				background-color: #e2edd4;
				transition: all .3s;

				&:hover {
					opacity:0.7;
				}

				&:before {
					content: "";
					display: block;
					width: 10px;
					height: 9px;
					background: url(../images/common/icon_arr_04.svg) no-repeat left center;
					background-size: 10px;
					position: absolute;
					top: 50%;
					left: 10px;
					margin-top: -4px;
				}
			}

			.next,
			&:last-child a {
				width: auto;
				padding: 0 26px 0 10px;
				border-radius: 15px;
				position: relative;
				background-color: #e2edd4;

				&:hover {
					opacity:0.7;
				}

				&:before {
					content: "";
					display: block;
					width: 10px;
					height: 9px;
					background: #e2edd4 url(../images/common/icon_arr_03.svg) no-repeat right center;
					background-size: 10px;
					position: absolute;
					top: 50%;
					right: 10px;
					margin-top: -4px;
				}
			}

			.dots {
				display: none;
				margin: 0;
			}
		}
	}
}

/* c-newsArchive
--------------------------------------------------------- */
.c-newsArchive {
	overflow: hidden;
	margin-bottom: 20px;
	
	@include max-screen($breakpoint-tablet-min) {
		margin-bottom: 60px;
	}

	&__list {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -35px;
		
		@include max-screen($breakpoint-pc-min) {
			margin: 0 -15px;
		}
		
		@include max-screen($breakpoint-tablet-min) {
			display: block;
			margin: 0;
		}
		
		&__item {
			width: 33.33334%;
			padding: 0 35px;
			margin-bottom: 70px;
			
			@include max-screen($breakpoint-pc-min) {
				padding: 0 15px;
			}
			
			@include max-screen($breakpoint-tablet-min) {
				width: auto;
				max-width: 360px;
				padding: 0;
				margin: 0 auto 40px;
			}
			
			&:last-child {
				margin-bottom: 0;
			}
		
			&__link {
				display: block;
				text-decoration: none;
				
				&:hover {
					
					.c-newsArchive__list__item__link__cat,
					.c-newsArchive__list__item__link__ttl {
						opacity: .7;
						transition: opacity $transition-slow;
					}
					
					.c-newsArchive__list__item__link__pic {
						
						img {
							transform: none;
						}
					}
				}
				
				&__cat {
					margin-bottom: 20px;
					font-size: 12px;
					font-weight: 600;
					letter-spacing: .025em;
					transition: transform $transition-slow;
					
					@include max-screen($breakpoint-tablet-min) {
						margin-bottom: 10px;
					}
				}
				
				&__ttl {
					margin-bottom: 45px;
					transition: transform $transition-slow;
					min-height: 50px;
					
					@include max-screen($breakpoint-tablet-min) {
						margin-bottom: 25px;
					}
				}
				
				&__pic {
					overflow: hidden;
					max-height: 195px;
					
					img {
						transform: scale(1.12,1.12);
						transition: transform $transition-slow;
						max-width: 100%;
						min-height: 195px;
					}
				}
			}
		}
	}
}
