// ---------------------------------------------------------------
//  newsDetail
// ---------------------------------------------------------------
.newsDetail {
    width: 1000px;
    margin: auto;
    opacity: 0;

    &.is-active {
        opacity: 1;
        transition: opacity $transition-slow;
        transition-delay: 500ms;
    }

    &__inner {

        text-align: center;

        &__tag {
            font-size: 14px;
            margin: 30px 0;

            &__date {
                margin-left: 60px;
            }
        }

        &__box {
            width: 800px;
            margin: auto;

            @include max-screen($breakpoint-tablet-min) {
                width: 100%;
            }

            &__ttl {
                font-size: 32px;
                margin-bottom: 30px;
                line-height: 35px;
            }

            &__txt {
                line-height: 28px;

                p {
                    margin-bottom: 10px;

                    img {
                        margin: 40px 0 50px;
                    }
                }
            }
        }
    }

    &__icon {

        width: 800px;
        margin: 90px auto;

        &__list {
            display: flex;
            width: 130px;
            justify-content: space-between;

            &__item {
              width: 30px;
            }
        }
    }

    .listGuide__btn {
        width: 800px;
        margin: auto;
        text-align: center;
        font-size: 14px;

        @include max-screen($breakpoint-tablet-min) {
            width: 100%;
            margin-bottom: 100px;
        }
    }
}

pre{
	padding:1em;
    background-color: #364549;
    color: #e3e3e3;
    white-space: pre-wrap ;
    margin:10px 0 10px 0;
}
code{
    font-family: Menlo, Consolas, 'DejaVu Sans Mono', monospace;
    font-size:0.8em;
}