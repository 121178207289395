@charset "utf-8";

@media print {
	body {
		//幅が広いサイトの場合縮小させる
		//width: 1240px;
		//margin: 0 auto;
		//transform: scale(0.85);
		//transform-origin: 0 0;
	}
	.u-sp-only {
		display: none !important;
	}
}
