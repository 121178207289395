// ---------------------------------------------------------------
//  topHero
// ---------------------------------------------------------------
.topHero {
	padding-left: 24px;
	height: 540px;
	margin-bottom: 50px;
	overflow: hidden;
	
	@include max-screen($breakpoint-tablet-min) {
		height: 370px;
		padding-right: 15px;
		padding-left: 15px;
		margin-bottom: 25px;
	}
	
	&.is-active {
	
		.topHero__inner__box__copy01 {
			opacity: 1.0;
			transform: none;
			filter: none;
			transition: opacity $transition-slow-x, transform $transition-slow-x, filter $transition-slow-x;
			
			&:before {
				transform: scale(1,1) translateX(105%);
				filter: none;
				transition: transform $transition-slow-x, filter $transition-slow-x;
			}
		}
		
		.topHero__inner__box__copy02 {
			opacity: 1;
			transition: opacity $transition-slow-x;
			transition-delay: 1600ms;
		}
		
		.topHero__inner__box__txt {
			opacity: 1;
			transition: opacity $transition-slow-x;
			transition-delay: 1600ms;
			word-wrap: break-word;
			width:70%;
		}
	}
	
	&__inner {
		display: flex;
		align-items: center;
		height: 100%;
	
		&__box {
			
			&__copy01 {
				margin-left: -10px;
				color: #f5f5f5;
				font-size: 140px;
				font-weight: 700;
				line-height: .8;
				letter-spacing: normal;
				white-space: nowrap;
				pointer-events: none;
				position: relative;
				opacity: 0;
				transform: scale(1.35,1.35);
				filter: blur(5px);
				
				@include max-screen(1399px) {
					font-size: (140/1400)*100vw;
				}
				
				@include max-screen($breakpoint-tablet-min) {
					margin-left: -4px;
				}
				
				&:before {
					content: '';
					display: block;
					width: 100%;
					height: 100%;
					position: absolute;
					top: 0;
					left: 0;
					z-index: 1;
					background-color: #fff;
					transform: scale(1.35,1.35);
					filter: blur(5px);
				}
			}
			
			&__copy02 {
				margin-left: -3px;
				margin-bottom: 10px;
				color: $font-color-black;
				font-size: 54px;
				font-weight: 700;
				line-height: 1;
				letter-spacing: normal;
				white-space: nowrap;
				position: relative;
				z-index: 1;
				transform: translateY(-50%);
				opacity: 0;
				
				@include max-screen($breakpoint-tablet-min) {
					font-size: (54/767)*100vw;
				}
			}
			
			&__txt {
				font-size: 18px;
				position: relative;
				z-index: 1;
				opacity: 0;
				
				@include max-screen($breakpoint-tablet-min) {
					font-size: 14px;
				}
			}
		}
	}
}

// ---------------------------------------------------------------
//  topPageLink
// ---------------------------------------------------------------
.topPageLink {
	margin-bottom: 200px;
	
	@include max-screen($breakpoint-tablet-min) {
		margin-bottom: 100px;
	}
	
	&__sec {
		margin-bottom: 200px;
		
		@include max-screen($breakpoint-tablet-min) {
			margin-bottom: 100px;
		}
		
		&:last-child {
			margin-bottom: 0;
		}
		
		&:nth-child(odd) {
			
			.topPageLink__sec__link__inner {
				flex-direction: row;
			}
			
			.topPageLink__sec__link__inner__picArea {
				
				&__pic {
					margin-left: calc((1200px - 100vw) /2);
					
					@include max-screen(1047px) {
						margin-left: -24px;
					}
					
					@include max-screen($breakpoint-tablet-min) {
						margin: 0 -15px;
					}
				}
			}
		}
		
		&:nth-child(even) {
			
			.topPageLink__sec__link__inner__picArea {
				
				&__pic {
					margin-right: calc((1200px - 100vw) /2);
					
					@include max-screen(1047px) {
						margin-right: -24px;
					}
					
					@include max-screen($breakpoint-tablet-min) {
						margin: 0 -15px;
					}
				}
			}
		}
		
		&.is-active {
		
			.topPageLink__sec__link__inner__picArea__pic {
				
				&__works,
				&__about {
					opacity: 1;
					transform: scale(1.05,1.05);
					transition: opacity $transition-slow, transform $transition-slow;
				}
			}
			
			.topPageLink__sec__link__inner__cont {
				opacity: 1;
				transition: opacity $transition-slow;
				transition-delay: 1000ms;
			}
		}
		
		&__link {
			display: block;
			text-decoration: none;
			
			&:hover {
				
				.topPageLink__sec__link__inner__picArea__pic {
					
					&__works,
					&__about {
						transform: none;
					}
				}
			
				.topPageLink__sec__link__inner__cont__link {
					
					&:before {
						transform: translateX(105%);
					}
				}
			}
			
			&__inner {
				display: flex;
				justify-content: space-between;
				align-items: center;
				flex-direction: row-reverse;
				
				@include max-screen($breakpoint-tablet-min) {
					display: block;
				}
				
				&__picArea {
					width: (660/1200)*100%;
					height: 520px;
					
					@include max-screen($breakpoint-tablet-min) {
						width: auto;
						height: 62vw;
						margin-bottom: 20px;
					}
					
					&__pic {
						height: 100%;
						overflow: hidden;
						
						&__works {
							@extend .topPageLink__sec__link__inner__picArea__pic;
							background: url(../images/top/works_pic01.png) no-repeat center;
							background-size: cover;
							opacity: 0;
							transform: scale(.95,.95);
						}
						
						&__about {
							@extend .topPageLink__sec__link__inner__picArea__pic;
							background: url(../images/top/about_pic01.png) no-repeat center;
							background-size: cover;
							opacity: 0;
							transform: scale(.95,.95);
						}
					}
				}
				
				&__cont {
					width: (430/1200)*100%;
					opacity: 0;
					
					@include max-screen($breakpoint-tablet-min) {
						width: auto;
					}
					
					&__ttl {
						margin-bottom: 15px;
						color: $font-color-black;
						font-size: 14px;
						font-weight: 600;
						letter-spacing: normal;
					}
					
					&__lead {
						margin-bottom: 30px;
						font-size: 22px;
						
						@include max-screen($breakpoint-tablet-min) {
							font-size: 18px;
						}
					}
					
					&__txt {
						margin-bottom: 40px;
					}
					
					&__link {
						display: inline-block;
						padding-bottom: 4px;
						font-size: 14px;
						font-weight: 600;
						letter-spacing: normal;
						position: relative;
						overflow: hidden;
						vertical-align: middle;
						
						&:before {
							content: "";
							display: block;
							width: 100%;
							height: 1px;
							background-color: $font-color-base;
							position: absolute;
							bottom: 0;
							left: 0;
							transform: none;
							transition: transform $transition-easeOutQuint-slow;
						}
					}
				}
			}
		}
	}
}

// ---------------------------------------------------------------
//  topNews
// ---------------------------------------------------------------
.topNews {
	margin-bottom: 180px;
	
	@include max-screen($breakpoint-tablet-min) {
		margin-bottom: 90px;
	}

	&__inner {
	
		&__more {
			font-size: 14px;
			font-weight: 600;
			text-align: center;
			
			a {
			}
		}
	}
}

// ---------------------------------------------------------------
//  topRecruit
// ---------------------------------------------------------------
.topRecruit {
	background-color: #f7f7f7;
	
	@include max-screen($breakpoint-tablet-min) {
	}
	
	&__inner {
		display: block;
		padding: 80px 0 60px;
		text-decoration: none;
		
		&:hover {
		
			.topRecruit__inner__box__txt01,
			.topRecruit__inner__box__ttl {
				opacity: .7;
			}
			
			.topRecruit__inner__box__link {
				
				&:before {
					transform: translateX(105%);
				}
			}
		}
	
		&__box {
			text-align: center;
			
			&__txt01 {
				margin-bottom: 20px;
				text-align: center;
				transition: opacity $transition-slow;
			}
			
			&__ttl {
				margin-bottom: 35px;
				color: $font-color-black;
				font-size: 32px;
				font-weight: 600;
				line-height: 1.4;
				text-align: center;
				transition: opacity $transition-slow;
				
				@include max-screen($breakpoint-tablet-min) {
					font-size: 26px;
				}
			}
			
			&__link {
				display: inline-block;
				padding-bottom: 4px;
				font-weight: 600;
				position: relative;
				overflow: hidden;
				text-decoration: none;
				vertical-align: middle;
				
				&:before {
					content: "";
					display: block;
					width: 100%;
					height: 1px;
					background-color: $font-color-base;
					position: absolute;
					bottom: 0;
					left: 0;
					transform: none;
					transition: transform $transition-easeOutQuint-slow;
				}
			}
		}
	}
}


.video {
	position: relative;
	height: 0;
	padding: 30px 0 56.25%;
	overflow: hidden;
  }
  .video iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border:none;
  }