// ---------------------------------------------------------------
//  worksMail
// ---------------------------------------------------------------
.worksMain {

    margin-bottom: 80px;
    opacity: 0;

    &.is-active {
        opacity: 1;
        transition: opacity $transition-slow;
        transition-delay: 800ms;
    }


    &__block {
        width: 1100px;
        margin: auto;
        margin-bottom: 200px;

        @include max-screen(1100px) {
            width: 100%;
            text-align: center;
        }
    }

    &__left {
        width: 500px;
        display: inline-block;
        margin-right: 45px;

        @include max-screen(1100px) {
            width: 40%;
        }

        @include max-screen($breakpoint-tablet-min) {
            width: 100%;
            margin-right: 0;
        }

        &__top {
            margin-bottom: 100px;

            @include max-screen($breakpoint-tablet-min) {
                margin-bottom: 60px;
            }
        }

        &__bottom {
            .worksMain__inner__txt {
                margin-left: 65px;
                @include max-screen($breakpoint-mobile-min) {
                    margin-left: 0;
                }
            }
        }

    }

    &__right {
        width: 500px;
        display: inline-block;
        vertical-align: top;
        margin-top: 460px;

        @include max-screen(1100px) {
            width: 40%;
        }

        @include max-screen($breakpoint-tablet-min) {
            width: 100%;
            margin-top: 60px;
        }

        &__center {
            margin-bottom: 100px;

            @include max-screen($breakpoint-tablet-min) {
                margin-bottom: 60px;
            }
        }


        .worksMain__inner__ttl {
            line-height: 20px;
            vertical-align: bottom;

            &:after {
                right: -70px;
                top: 8px;
            }
        }

        .worksMain__inner__ttl__2 {
            line-height: 20px;
            vertical-align: bottom;
        }

        .worksMain__inner__txt {
            margin-left: 155px;

            @include max-screen($breakpoint-mobile-min) {
                margin-left: 0;
            }
        }

        .worksMain__inner__txt__2 {
            margin-left: 155px;

            @include max-screen($breakpoint-mobile-min) {
                margin-left: 0;
            }
        }
    }

    &__inner {
        // margin: 0 50px;

        &__ttl, &__ttl__2  {
            font-size: 24px;
            display: inline;
            position: relative;

            &:after {
                content: "";
                background: url(../images/common/slsh.png)no-repeat;
                background-size: 100%;
                position: absolute;
                height: 60px;
                width: 70px;
                right: -45px;
            }
        }

        &__txt, &__txt__2 {
            margin-left: 120px;
            margin-bottom: 40px;
            font-size: 16px;
            line-height: 24px;

            @include max-screen($breakpoint-mobile-min) {
                margin-top: 60px;
                margin-left: 0;
            }
        }

        &__img {
            text-align: center
        }
    }

}

// ---------------------------------------------------------------
//  worksList
// ---------------------------------------------------------------
.worksList {
    width: 1000px;
    margin: auto;
    opacity: 0;

    &.is-active {
        opacity: 1;
        transition: opacity $transition-slow;
        transition-delay: 500ms;
    }

    @include max-screen(900px) {
        width: 100%;
    }

    @include max-screen($breakpoint-tablet-min) {
        padding: 0 40px;
    }

    .c-title {
        font-size: 24px;

        &:after {
            height: 60px;
            width: 70px;
            right: -45px;
        }
    }

    &__list {
        margin-top: 60px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        @include max-screen(580px) {
            justify-content: center;
        }

        &__item {
            width: 300px;
            margin-bottom: 60px;
            max-height:600px;

            @include max-screen(900px) {
                width: 250px;
            }

            @include max-screen($breakpoint-tablet-min) {
                margin-bottom: 100px;
            }

        }

        &__ttl {
            margin: 30px 0 20px;
            font-size: 25px;
            font-weight: 900;
            color:#555;
        }

        &__txt {
            font-size: 14px;
            line-height: 20px;
        }

        &__subtxt {
            margin-top: 20px;
            font-size: 14px;
            line-height: 20px;

            &__head {
                width: 25%;
                float: left;
            }
            &__content {
                width: 75%;
                float: left;
            }
        }
    }
}
